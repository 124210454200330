import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateLastInteractionTime } from 'fwi-fe-components';
import { CurrentUserEntity, EntityId } from 'fwi-fe-types';
import { authTokenController, getCompanyId, StatusCodes } from 'fwi-fe-utils';
import { difference } from 'lodash';
import { stringify } from 'qs';

import {
  APIRejection,
  APIRejectionThunkConfig,
  APIStatusRejectionThunkConfig,
  AppThunkConfig,
  IdentityProvider,
} from 'appTypes';
import {
  COMPANY_AUTH_ENDPOINT,
  IDP_ENDPOINT,
  LOGOUT_ENDPOINT,
  NO_COMPANY_AUTH_ENDPOINT,
  OKTA_AUTHORIZE_ENDPOINT,
  TOKEN_EXCHANGE_ENDPOINT,
} from 'constants/endpoints';
import { OKTA_CLIENT_ID, PIDS_IDP } from 'constants/env';
import { OKTA_SCOPES, PIDS_SCOPES } from 'constants/okta';
import { api } from 'utils/api';
import { createSecurityCookies } from 'utils/auth';
import { logout } from 'utils/logout';
import { REDIRECT_URI } from 'utils/routes';

import { getCurrentUser } from './selectors';

/**
 * Get the IdentityProvider for the provided email.
 *
 * @param email - The email address
 * @returns the {@link IdentityProvider} or `null` if the email isn't attached
 * to an `IdentityProvider`
 */
export const fetchIdp = createAsyncThunk<
  IdentityProvider | null,
  string,
  APIRejectionThunkConfig
>('auth/idp', async (email, { rejectWithValue }) => {
  const response = await api(`${IDP_ENDPOINT}/${encodeURIComponent(email)}`);

  if (!response.ok) {
    return rejectWithValue({
      status: response.status,
      unmodified: response.status === StatusCodes.NOT_MODIFIED,
    });
  }

  const json: IdentityProvider = await response.json();
  // if there is no IdentityProvider for the provided email, an empty object
  // will be returned. This also means that we need to use Okta login
  return json.idpId ? json : null;
});

/**
 * This isn't actually a redux action and instead just a redirect to the okta
 * authorize endpoint with the correct query parameters.
 *
 * @param idp - the identity provider id attached with user's email
 * @param email - the user's email
 */
export const signInWithIdentityProvider = (
  idp: string,
  email: string
): void => {
  const { state, nonce } = createSecurityCookies();
  updateLastInteractionTime();

  const query = stringify({
    response_type: 'code',
    client_id: OKTA_CLIENT_ID,
    scope: OKTA_SCOPES.join(' '),
    redirect_uri: REDIRECT_URI,
    nonce,
    state,
    idp,
    login_hint: email,
  });

  window.location.replace(`${OKTA_AUTHORIZE_ENDPOINT}?${query}`);
};

export interface SignInWithPidsOptions {
  companyId: string;
}

export const signInWithPids = ({ companyId }: SignInWithPidsOptions): void => {
  const { state, nonce } = createSecurityCookies();
  updateLastInteractionTime();

  const query = stringify({
    response_type: 'code',
    client_id: OKTA_CLIENT_ID,
    scope: PIDS_SCOPES.join(' '),
    redirect_uri: REDIRECT_URI,
    nonce,
    state,
    idp: PIDS_IDP,
    login_hint: `fwi_company_id:${companyId}`,
  });

  window.location.replace(`${OKTA_AUTHORIZE_ENDPOINT}?${query}`);
};

/**
 * This takes the `code` provided by Okta and exchanges for an access token to
 * use within Cloud. If this is successful, the following cookies will be set:
 * - {@link FWI_ID_TOKEN}
 * - {@link FWI_ACCESS_TOKEN}
 *
 * @param code - code that is used to create an access token and authenticate
 * the current user
 */
export const exchangeAuthToken = createAsyncThunk<
  void,
  string,
  AppThunkConfig<APIRejection & { message: string }>
>('auth/exchangeTokens', async (code, { rejectWithValue }) => {
  const response = await fetch(TOKEN_EXCHANGE_ENDPOINT, {
    mode: 'cors',
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      code,
      redirectUri: encodeURIComponent(REDIRECT_URI),
      clientId: OKTA_CLIENT_ID,
    }),
  });

  if (response.ok) {
    return;
  }

  const message = await response.text();

  return rejectWithValue({
    message,
    status: response.status,
    unmodified: response.status === StatusCodes.NOT_MODIFIED,
  });
});

/**
 * This will send a request to the BE to remove the current access token from
 * the redis cache which "invalidates" the session.
 */
export const removeSession = async (): Promise<void> => {
  const accessToken = await authTokenController.getAccessToken();
  if (!accessToken || !authTokenController.isAccessTokenValid()) {
    return;
  }

  await fetch(LOGOUT_ENDPOINT, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({}),
  }).catch(() => {});
};

/**
 * Fetches the current user information based on if the user has selected a
 * company or not.
 */
export const fetchCurrentUser = createAsyncThunk<CurrentUserEntity, void>(
  'auth/currentUser',
  async () => {
    const companyId = getCompanyId();
    const response = await api(
      companyId ? COMPANY_AUTH_ENDPOINT : NO_COMPANY_AUTH_ENDPOINT,
      { checkStatus: false }
    );

    if (!response.ok) {
      const { status } = response;
      logout(
        status === StatusCodes.UNAUTHORIZED
          ? 'SessionUnauthorized'
          : 'AccountRemoved'
      );

      // just so it rejects
      throw new Error();
    }

    return await response.json();
  }
);

export interface CurrentUserPatch {
  isTrackAnalyticsAllowed: boolean;

  /**
   * The list of company ids to keep on the current user.
   */
  companies: readonly EntityId[];
}

/**
 * Updates the current user's authorization or allowed analytics. Nothing else is
 * supported at this time through this endpoint.
 *
 * @param patch - {@link CurrentUserPatch}
 */
export const patchCurrentUser = createAsyncThunk<
  void,
  CurrentUserPatch,
  APIStatusRejectionThunkConfig
>(
  'auth/patchCurrentUser',
  async (patch, { rejectWithValue }) => {
    const response = await api(COMPANY_AUTH_ENDPOINT, {
      method: 'PATCH',
      body: JSON.stringify(patch),
    });

    if (!response.ok) {
      const { status, statusText } = response;
      return rejectWithValue({ status, statusText });
    }
  },
  {
    condition: (patch, { getState }) => {
      const user = getCurrentUser(getState());
      const companies = user?.companies?.map(({ id }) => id) ?? [];

      return (
        user?.isTrackAnalyticsAllowed !== patch.isTrackAnalyticsAllowed ||
        difference(patch.companies, companies).length === 0
      );
    },
  }
);
